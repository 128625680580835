import { getConfig } from "./Utils";
var app = getConfig();

export function getDemonstration(demoId, callback) {
    try{
        var path = "/demonstration/get/complexId/" + demoId;
        var url = app.DKW_URL + path;

        var request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    try {
                        var results = JSON.parse(request.response);
                        callback(results);
                    } catch(e) {
                        console.log(e)
                        callback({
                            error : true
                        });
                    }
                }
            }
        };
        request.open('GET', url);
        request.send();
    }catch(e){
        console.log(e)
    }
}