<template>
    <div class="col-md-12" style="padding-top: 60px;padding: 0px;">
        <div class="scanCamera">
            <div id="bagScanCamera" ref="bagScanCamera">
                <img style="float:left;" src="@/assets/img/ok-bag.svg"/>
                <div class="explainScan" ref="explainScan">
                    <p ref="explainScanValue">{{$t('messageScanExplainParameters')}} </p>
                </div>
            </div>

            <div id="bagCartCamera" ref="bagCartCamera">
                <img style="float:right;" src="@/assets/img/bag-pointing.svg"/>
                <div class="explainCart" ref="explainCart">
                    <p ref="explainCartValue">{{explainCartValue}} </p>
                </div>
            </div>

            <qrcode-reader id="scanner" ref="scanner" class="cameraCustom" @decode="onDecode"></qrcode-reader>

            <Alert v-if="alertContent!=''" :alertContent="alertContent" :alertVariant="'danger'"></Alert>

            <div id="barcode" ref="barcode" class="cameraCustom" hidden></div>
        </div>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
    import Alert from "@/components/Common/Alert";
    var App = require("@/assets/js/App");
    import {isNotNull, getConfig} from "@/assets/js/Utils"

    export default {
        name: "NeedScan",
        components: {Alert},
        data() {
            return {
                alertContent: "",
                explainScanValue: this.$t('messageScanExplain'),
                explainCartValue: this.$t('messageCartExplain'),
                needParameters: this.$route.query.needParameters,
            }
        },
        mounted(){
            this.$refs.scanner.$el.style.height = window.innerHeight + "px";
            document.getElementsByClassName("qrcode-reader__camera")[0].style.height = window.innerHeight + "px";
            document.getElementsByClassName("qrcode-reader__inner-wrapper")[0].style.height = window.innerHeight + "px";
        },
        methods: {
            onDecode(decodedString) {
                this.$refs.beep.play();

                var controlKey = "/p=";
                if(decodedString.includes(controlKey)){
                    var pathname = decodedString.substring(window.location.origin.length);
                    App.checkParameters(pathname, this);
                    this.setBase();
                    localStorage.setItem("DKC_needPublishPoster", "true");
                    window.location.replace(window.location.origin + localStorage.getItem("DKC_base") + '/');
                }else{
                    this.alertContent = this.$t('scanErrorParameters');
                }

            },
            
            setBase: function(){
                var referer = "DKC";
                var univers = localStorage.getItem("DKC_univers");
                
                var universRoute = "";
                switch(univers){
                    case "1":
                        universRoute = "/fashion"
                        break;
                    case "2":     
                    case "4":
                    case "5":
                    case "6":
                        universRoute = "/food"
                        break;
                    case "3":
                        universRoute = "/fuel"
                        break;
                }
                var demoId = localStorage.getItem("DKC_demoId");
                var scanType = localStorage.getItem("DKC_shortScanType");
                var complexIdParent = localStorage.getItem("DKC_complexId");

                if(isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType)){
                    if(isNotNull(complexIdParent)){
                        this.base = universRoute + "/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType + "_" + complexIdParent;
                    }else{
                        this.base = universRoute + "/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType;
                    }
                }
                localStorage.setItem("DKC_base", this.base);
            }
        }
    }
</script>